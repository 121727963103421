<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 第三方考试人员 </template>
      <template #input>
        <a-input placeholder="请输入姓名" v-model="name" />
        <a-input placeholder="请输入手机号" v-model="mobile" />
        <a-input placeholder="请输入身份证号" v-model="idNo" />
        <a-input placeholder="请输入三方来源" v-model="studentSource" />
        <a-select placeholder="选择考试" allowClear v-model="examConfigId">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option
            v-for="item in examList"
            :key="item.configId"
            :value="item.configId"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-button type="primary" class="btn" @click="onSearch()">搜索</a-button>
        <a-button class="btn all_boder_btn" @click="onDetail(1)">新建</a-button>
        <a href="https://cos.hxclass.cn/prod/template/exam/%E4%B8%89%E6%96%B9%E8%80%83%E8%AF%95%E4%BA%BA%E5%91%98.xlsx" target="_blank" rel="noopener noreferrer">
          <a-button class="btn all_boder_btn">下载模板</a-button>
        </a>
        <a-button class="all_boder_btn" @click="editFilling">批量导入</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="item=>item.tripartiteId"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total: total,
          current: current,
          defaultsize: size,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          {{ (current - 1) * size + i + 1 }}
        </template>
       
        <template slot="operation" slot-scope="text, record">
          <div class="blueText">
            <span @click="onDetail(2, record.tripartiteId)">编辑</span>
            <span> | </span>
            <span @click="deleteData(record.tripartiteId)">删除</span>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 新建第三方人员 -->
    <a-modal
      v-model="isValidityShow"
      align="center"
      width="500px"
      :title="oper == 1?'新建三方考试人员':'编辑三方考试人员'"
    >
      <div class="all_content_box">
        <div class="line-item">
          <span class="all_left_name all_required">姓名：</span>
          <a-input
            class="right"
            placeholder="请输入姓名"
            v-model="detailDate.name"
          />
        </div>
        <div class="line-item">
          <span class="all_left_name all_required">证件：</span>
          <a-select
            placeholder="请选择证件类型"
            class="right"
            v-model="detailDate.idType"
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option :value="1">居民身份证</a-select-option>
            <a-select-option :value="2">港澳台通行证</a-select-option>
            <a-select-option :value="3">护照</a-select-option>
          </a-select>
        </div>
        <div class="line-item">
          <span class="all_left_name"></span>
          <a-input
            class="right"
            placeholder="请输入证件号"
            v-model="detailDate.idcard"
          />
        </div>
        <div class="line-item">
          <span class="all_left_name all_required">手机号：</span>
          <a-input
            class="right"
            placeholder="请输入手机号"
            v-model="detailDate.mobile"
          />
        </div>
        <div class="line-item">
          <span class="all_left_name all_required">考试名称：</span>
          <a-select
            placeholder="请选择考试名称"
            v-model="detailDate.examId"
            class="right"
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option
              v-for="item in examList"
              :key="item.configId"
              :value="item.configId"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="line-item">
          <span class="all_left_name all_required">课程：</span>
          <!-- 个人商品订单 模糊搜索 -->
          <a-select
            class="right"
            show-search
            v-model="detailDate.courseId"
            placeholder="请选择课程"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="filterOption"
            :not-found-content="null"
          >
            <a-select-option v-for="item in courseList" :key="item.courseId">
              {{ item.courseName }}
            </a-select-option>
          </a-select>
        </div>
        <div class="line-item">
          <span class="all_left_name all_required">三方来源：</span>
          <a-select
            placeholder="请选择三方来源"
            v-model="detailDate.source"
            class="right"
          >
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option :value="1">华夏</a-select-option>
            <a-select-option :value="2">恩启</a-select-option>
            <a-select-option :value="3">ALSOLIFE</a-select-option>
            <a-select-option :value="4">广东省生源</a-select-option>
          </a-select>
        </div>
        <div class="line-item">
          <span class="all_left_name all_required">考试次数：</span>
          <a-input
            class="right"
            placeholder="请输入考试次数"
            v-model="detailDate.examNum"
          />
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="PreventLoad" @click="onSave(detailDate.tripartiteId)"
          >确认</a-button
        >
        <a-button @click="isValidityShow = false">取消</a-button>
      </template>
    </a-modal>

    <a-modal
      v-model="edit"
      width="530px"
      title="批量导入"
      :centered="true"
      :closable="false"
    >
      <div class="all_content_box">
        <div class="L_R_inner">
          <span class="all_left_name">EXCEL：</span>
          <div class="right_Div">
             <a-upload 
              :file-list="fileList2"
              :remove="handleRemove2"
              :beforeUpload="beforeUpload2"
              name="file"
              :customRequest="fileAction">
            <a-button>
              <a-icon type="upload" />选择文件</a-button>
          </a-upload>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button @click="editCancel">取消</a-button>
      </template>
    </a-modal>

    <!-- 信息确认 -->
    <a-modal
      v-model="askInfoShow"
      width="1100px"
      title="确认信息"
      :centered="true"
      :closable="false"
    >
      <div class="content_info">
        <div class="head_btn">
          <span class="item_btn" :class="{'item_btn_hover' : btnIndex == 1}" @click="onBtnClick(1)">正确数据</span>
          <span class="item_btn" :class="{'item_btn_hover' : btnIndex == 2}" @click="onBtnClick(2)">错误数据</span>
        </div>
        <div class="table">
          <a-table
            class="table-template"
            :rowKey="(item,index) => index"
            :columns="excelColumns"
            :loading="excelLoad"
            :data-source="excelData"
            @change="onExcelPage">
            <template slot="index" slot-scope="item, row, i">
              {{ (excelCurrent - 1) * 10 + i + 1 }}
            </template>

            <template slot="name" slot-scope="item">
              <span :class="{'state_color_red' : errorArry.indexOf(item) != -1}">{{item}}</span>
            </template>
            <template slot="idType" slot-scope="item">
              <span :class="{'state_color_red' : errorArry.indexOf(item) != -1}">{{item}}</span>
            </template>
            <template slot="idcard" slot-scope="item">
              <span :class="{'state_color_red' : errorArry.indexOf(item) != -1}">{{item}}</span>
            </template>
            <template slot="source" slot-scope="item">
              <span :class="{'state_color_red' : errorArry.indexOf(item) != -1}">{{item}}</span>
            </template>
            <template slot="mobile" slot-scope="item">
              <span :class="{'state_color_red' : errorArry.indexOf(item) != -1}">{{item}}</span>
            </template>
            <template slot="examConfig" slot-scope="item">
              <span :class="{'state_color_red' : errorArry.indexOf(item) != -1}">{{item}}</span>
            </template>
            <template slot="courseName" slot-scope="item">
              <span :class="{'state_color_red' : errorArry.indexOf(item) != -1}">{{item}}</span>
            </template>
            <template slot="examNum" slot-scope="item">
              <span :class="{'state_color_red' : errorArry.indexOf(item) != -1}">{{item}}</span>
            </template>
          </a-table>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" v-show="btnIndex == 1" :loading="excelLoad" @click="onImportData">导入正确数据</a-button>
        <a-button type="primary" v-show="btnIndex == 2" @click="onExportData">导出错误数据</a-button>
        <a-button @click="askInfoShow = false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "证件类型",
    align: "center",
    dataIndex: "idType",
  },
  {
    title: "身份证号",
    align: "center",
    dataIndex: "idcard",
  },
  {
    title: "三方来源",
    align: "center",
    dataIndex: "source",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "考试名称",
    align: "center",
    dataIndex: "examName",
  },
  {
    title: "课程名称",
    align: "center",
    dataIndex: "courseName",
  },
  {
    title: "考试次数",
    align: "center",
    dataIndex: "examNum",
  },
  {
    title: "操作",
    align: "center",
    width: "200px",
    scopedSlots: { customRender: "operation" },
  },
];

const excelColumns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "证件类型",
    align: "center",
    dataIndex: "idType",
    scopedSlots: { customRender: "idType" },
  },
  {
    title: "身份证号",
    align: "center",
    dataIndex: "idcard",
    scopedSlots: { customRender: "idcard" },
  },
  {
    title: "三方来源",
    align: "center",
    dataIndex: "source",
    scopedSlots: { customRender: "source" },
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
    scopedSlots: { customRender: "mobile" },
  },
  {
    title: "考试名称",
    align: "center",
    dataIndex: "examConfig",
    scopedSlots: { customRender: "examConfig" },
  },
  {
    title: "课程名称",
    align: "center",
    dataIndex: "courseName",
    scopedSlots: { customRender: "courseName" },
  },
  {
    title: "考试次数",
    align: "center",
    dataIndex: "examNum",
    scopedSlots: { customRender: "examNum" },
  },
];

import HeaderBox from "@/components/HeaderBox.vue";
import $config from '@/unit/config.js'

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      oper: 1,
      edit: false,
      askInfoShow:false,
      btnIndex:1,
      isValidityShow: false,
      examList: [],
      fileList2: [],
      columns, // 表头
      tableData: [], // 列表数据
      courseList: [], // 课程列表

      excelColumns,
      excelTotal:0,
      excelData:[],
      excelSuccessData:[],
      excelfailData:[],
      excelCurrent:1,
      excelSize:10,
      excelLoad:false,

      PreventLoad:false,

      loading: false, // 列表加载
      total: 0, // 总数量
      current: 1, //页码
      size: 10, // 页数
      name: "",
      mobile: "",
      idNo: "",
      studentSource: "",
      examConfigId:undefined,
      detailDate: {
        courseId: undefined,
        creator: "",
        examId: undefined,
        examNum: "",
        idType: undefined,
        idcard: "",
        institution: "",
        mobile: "",
        modifier: "",
        name: "",
        source: undefined,
        surplusNu: "",
        tripartiteId: "",
        userId: "",
      },
      errorArry: ['课程名称不存在', '考试名称不存在', '手机号错误', '身份证号错误', '值为空']
    };
  },
  // 事件处理器
  methods: {
    // 关联课程快捷搜索
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 分页
    onPage(e) {
      // console.log(e)
      this.current = e.current;
      this.size = e.pageSize;
      this.getList();
    },

    // 导入的数据分页
    onExcelPage(e){
      this.excelCurrent = e.current;
      this.excelSize = e.size;
    },
    onSearch() {
      this.current = 1
      this.getList();
    },
    getExam() {
      this.$ajax({
        url: "/hxclass-management/exam/config/exam/tab/list",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.examList = res.data;
        }
      });
    },
    beforeUpload2 (file) {
      const format = /\.(xlsx)(\?.*)?$/
      if (!format.test(file.name)) {
        this.$message.warning('文件格式只支持 xlsx ！')
        this.loading = false
        return false
      }
      this.fileList2 = [file]
      // return false
    },
    handleRemove2 (file) {
      const index = this.fileList2.indexOf(file);
      const newFileList = this.fileList2.slice();
      newFileList.splice(index, 1);
      this.fileList2 = newFileList;
    },
    editCancel(e) {
      this.edit = false;
    },
    editFilling() {
      this.fileList2 = [];
      this.edit = true;
    },
    getList(e) {
      this.loading = true
      this.$ajax({
        url:
          "/hxclass-management/exam/tripartite/list?current=" +
          this.current +
          "&size=" +
          this.size,
        params: {
          // name: this.name != "" ? this.name : this.mobile != "" ? this.mobile : this.idNo != "" ? this.idNo : "",
          name: this.name,
          mobile: this.mobile,
          idcard: this.idNo,
          source: this.studentSource,
          examConfigId: this.examConfigId,
        },
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.total = res.data.total
          this.tableData = res.data.records;
        }
      });
    },
    onDetail(operation, id) {
      this.oper = operation;
      this.isValidityShow = true;
      if (operation == 2) {
        this.getDetail(id);
        this.getExam();
      }
    },
    getDetail(id) {
      this.$ajax({
        url: "/hxclass-management/exam/tripartite/get",
        params: {
          tripartiteId: id,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.detailDate = res.data;
        }
      });
    },

    // 非空字段校验
    FunCheck(){
      let isCheck = false
      if(!this.detailDate.name){ // 校验姓名
        isCheck = true
        this.$message.warning("请输入姓名");
        return isCheck
      }
      if(!this.detailDate.idType){ // 校验证件类型
        isCheck = true
        this.$message.warning("请选择证件类型");
        return isCheck
      }
      if(!this.detailDate.idcard){ // 校验证件号
        isCheck = true
        this.$message.warning("请输入证件号");
        return isCheck
      }
      // 校验身份证
      // if((this.detailDate.idType == 1) && !this.$regular.id_card.reg.test(this.detailDate.idcard)){
      //   isCheck = true
      //   this.$message.warning(this.$regular.id_card.msg);
      //   return isCheck
      // }
      // // 校验港澳通行证
      // if((this.detailDate.idType == 2) && !this.$regular.hongKong_card.reg.test(this.detailDate.idcard)){
      //   isCheck = true
      //   this.$message.warning(this.$regular.hongKong_card.msg);
      //   return isCheck
      // }
      // // 校验护照
      // if((this.detailDate.idType == 3) && !this.$regular.passport_card.reg.test(this.detailDate.idcard)){
      //   isCheck = true
      //   this.$message.warning(this.$regular.passport_card.msg);
      //   return isCheck
      // }
      if(!this.detailDate.mobile){ // 校验手机号
        isCheck = true
        this.$message.warning("请输入手机号");
        return isCheck
      }
      if(!this.$regular.phone.reg.test(this.detailDate.mobile)){
        isCheck = true
        this.$message.warning(this.$regular.phone.msg);
        return isCheck
      }
      if(!this.detailDate.examId){ // 校验考试名称
        isCheck = true
        this.$message.warning("请选择考试名称");
        return isCheck
      }
      if(!this.detailDate.courseId){ // 课程
        isCheck = true
        this.$message.warning("请选择课程");
        return isCheck
      }
      if(!this.detailDate.source){ // 校验三方来源
        isCheck = true
        this.$message.warning("请选择三方来源");
        return isCheck
      }
      if(!this.detailDate.examNum){ // 校验考试次数
        isCheck = true
        this.$message.warning("请输入考试次数");
        return isCheck
      }
      if(!this.$regular.input_number.reg.test(this.detailDate.examNum)){ // 校验考试次数
        isCheck = true
        this.$message.warning("考试次数请输入数字");
        return isCheck
      }
    },
    onSave(e) {
      if(this.FunCheck()) return
      var url = "";
      var method = "";
      if (e) {
        // 编辑
        url = "/hxclass-management/exam/tripartite/edit";
        method = "put";
      } else {
        // 新增
        url = "/hxclass-management/exam/tripartite/save";
        method = "post";
      }
      this.PreventLoad = true
      this.$ajax({
        url: url,
        method: method,
        params: this.detailDate,
      }).then((res) => {
        this.PreventLoad = false
        if (res.code == 200 && res.success) {
          this.isValidityShow = false;
          this.getList();
        }else{
          this.$message.warning(res.message)
        }
      });
    },
    deleteData(e) {
      this.$confirm({
        title: "确定删除吗?",
        okText: "确认",
        onOk: () => {
          this.$ajax({
            url: "/hxclass-management/exam/tripartite/del?tripartiteId=" + e,
            method: "delete",
          }).then((res) => {
            if (res.code == 200 && res.success) {
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },

    onBtnClick(i){
      this.btnIndex = i
      if( i == 1){
        this.excelData = this.excelSuccessData
      } else {
        this.excelData = this.excelfailData
      }
    },
    // 获取导入的基础信息
    fileAction(e){
      this.$ajax({
        url: '/hxclass-management/exam/tripartite/import/certifinfo',
        method: 'post',
        headers: [
          {type: 'file'}
        ],
        params:{
          file: e.file,
          name: 'file'
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
         this.excelSuccessData = res.data.success  // 正确数据
         this.excelfailData = res.data.failure  // 错误数据
         this.btnIndex = 1
         this.excelData = res.data.success   // 默认展示正确数据

         this.askInfoShow = true;
         this.edit = false;
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        this.$message.error(err)
      })
    },

    // // 下载错误excel
    // downloadExcel() {
    //   if (!this.failureList.length) {
    //     return;
    //   }
    //   window.open(
    //     this.$config.target +
    //       "/hxclass-management/exam/certif/export/certifinfo?errorJson=" +
    //       encodeURIComponent(JSON.stringify(this.failureList)) +
    //       "&type=2"
    //   );
    // },

    // // 导入正确数据
    // importSuccessList() {
    //   let arr = [];
    //   this.successList.forEach((element) => {
    //     arr.push({
    //       subId: element.subId,
    //       shipStatus: 1,
    //       logisticsCompany: element.logisticsCompany,
    //       logisticsCode: element.logisticsNumber,
    //     });
    //   });
    //   arr = JSON.stringify(arr);
    //   this.$ajax({
    //     url: "/hxclass-management/exam/certif/bacth-import",
    //     method: "POST",
    //     params: {
    //       recordId: this.recordId,
    //       generateJson: arr,
    //       type: 2,
    //     },
    //   }).then((res) => {
    //     if (res.code == 200 && res.success) {
    //       this.$message.success(res.message);
    //       this.getData();
    //       this.importVisible = false;
    //     } else {
    //       this.$message.error(res.message);
    //     }
    //   });
    // },
    // 确认导入数据
    onImportData(){
      if(!this.excelData.length){
        this.$message.error('数据为空，无法导入')
        return
      }
      this.excelLoad = true
      let arry = []

      // 数据格式处理
      this.excelData.forEach(e => {
        e.idType = e.idType == '中国居民身份证' ? 1 : e.idType == '港澳台居民居住证' ? 2 : e.idType == '护照' ? 3 : null
        arry.push(
          {
            courseId: e.courseId,
            examId: e.examId,
            examNum: e.examNum,
            idType: '中国居民身份证' ? 1 : e.idType == '港澳台居民居住证' ? 2 : e.idType == '护照' ? 3 : null,
            idcard: e.idcard,
            mobile: e.mobile,
            name: e.name,
            source: e.source == '华夏' ? 1 : e.source == '恩启' ? 2 : e.source == 'ALSOLIFE' ? 3 : e.source == '广东省生源' ? 4 : null,
          }
        )
      });

      this.$ajax({
        url: '/hxclass-management/exam/tripartite/batch/save',
        method: 'post',
        params: {
          tripartiteJson:JSON.stringify(arry)
        },
      }).then((res) => {
        this.excelLoad = false
        if (res.code == 200 && res.success) {
          this.$message.success(res.message)
          this.askInfoShow = false
          this.getList();
        }else{
          this.$message.warning(res.message)
        }
      });
    },
    // 导出错误数据
    // onExportData(){
    //   if(!this.excelData.length){
    //     this.$message.error('数据为空，无法导入')
    //     return
    //   }
    //   this.askInfoShow = false
    //   window.open(
    //     this.$config.target +
    //       "/hxclass-management/exam/tripartite/export/error/tripartite?errorJson=" +
    //       encodeURIComponent(JSON.stringify(this.excelData))
    //   );
    // }
       // 下载错误excel
    onExportData() {
      if (!this.excelData.length) {
        return;
      }
      this.askInfoShow = false
      this.$ajax({
        url: "/hxclass-management/exam/tripartite/export/error/tripartite",
        method: "post",
        headers: [{type: 'file'}],
        params: {
          errorJson: JSON.stringify(this.excelData)
        },
        responseType: 'blob'
      }).then((res) => {
        this.blobDownload(res,'错误文件')
      });
    },

    // blob文件转excel
    blobDownload(data,name){
      let m=this;
      var content = data;
      // var data = new Blob([content],{type:"application/octet-stream;charset=utf-8"});
      var data = new Blob([content],{type:"application/vnd.ms-excel;charset=utf-8"});
      var downloadUrl = window.URL.createObjectURL(data);
      var anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = name+".xls";
      anchor.click();
      window.URL.revokeObjectURL(data);
    },

    // 获取课程列表
    getCourseList() {
      this.$ajax({
        url: '/hxclass-management/course/boxNoPage'
      }).then((res)=>{
        if(res.code == 200 && res.success) {
          this.courseList = res.data;
        }else{
          this.$message.error(res.message);
        }
      })
    }
  },

  // 生命周期-实例创建完成后调用
  created() {
    this.getList();
    this.getExam();
    this.getCourseList();
  },
  // 生命周期-实例挂载后调用
  mounted() {
    
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    isValidityShow(newName) {
      if (!newName) {
        setTimeout(() => {
          this.detailDate = {};
        }, 100);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.btn {
  margin: 0 24px 14px 0;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
.readfile{
  margin-left: 12px;
  font-size: 14px;
  font-weight: bold;
}
.all_content_box {
  .line-item {
    margin-bottom: 10px;
    display: flex;
    .all_left_name {
      width: 90px;
      text-align: right;
      margin-right: 20px;
      line-height: 32px;
    }
  }
  .line-item:last-child {
    margin-bottom: 0;
  }
  .right {
    flex: 1;
  }
  textarea.ant-input {
    flex: 1;
  }
}
/deep/ .ant-modal-footer {
  text-align: center;
}
/deep/  .ant-upload-list-item{
  width: 180px;
}

.content_info{
  .head_btn{
    display: inline-block;
    .item_btn{
      display:inline-block;
      text-align: center;
      line-height: 40px;
      width: 90px;
      height: 40px;
      border: 1px solid #999999;
      cursor: pointer;
    }
    .item_btn:nth-child(1){
      border-right: none;
    }
    .item_btn_hover{
      background: @theme;
      color: #ffffff;
    }
  }
}
</style>


